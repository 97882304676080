<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      balance: 'R$ -,--',
      orders: {
        pendings: {
          qty: '0',
          total: 'R$ -,--',
        },
        approveds: {
          qty: '0',
          total: 'R$ -,--',
        }
      },
    };
  },
  methods: {
    getSaldo() {
      api.get("financeiro/saldo").then((res) => {
        if (res.data.status == "success") {
          this.balance = this.$options.filters.currency(res.data.balance);
        }
      });
    },
    getPedidosTotalPendentes() {
      api.get("pedidos/total/pendentes").then((res) => {
        if (res.data.status == "success") {
          this.orders.pendings.qty = res.data.orders.qty;
          this.orders.pendings.total = this.$options.filters.currency(res.data.orders.total);
        }
      });
    },
    getPedidosTotalAprovados() {
      api.get("pedidos/total/aprovados").then((res) => {
        if (res.data.status == "success") {
          this.orders.approveds.qty = res.data.orders.qty;
          this.orders.approveds.total = this.$options.filters.currency(res.data.orders.total);
        }
      });
    },
  },
  mounted() {
    this.getSaldo();
    this.getPedidosTotalPendentes();
    this.getPedidosTotalAprovados();
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Painel</h3>
        <p></p>
      </div>
    </div>

    <a href="https://chat.whatsapp.com/F0AgttqIbV239iPU4kKFNN">
      <div class="card bg-soft-success">
        <div class="card-body d-flex align-items-center justify-content-center py-md-2">
          <div>
            <img style="height: 40px;" src="@/assets/images/logo-whatsapp.png" />
          </div>
          <div class="ml-3 text-dark">
            <h5 class="m-0">Acesse o Grupo Vip no Whatsapp</h5>
          </div>
        </div>
      </div>
    </a>

    <div class="row">
      <div class="col-md-6 col-lg-3">
        <div class="card">
          <div class="card-body">
            <div>Saldo disponível</div>
            <h3 class="m-0">{{ this.balance }}</h3>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="card">
          <div class="card-body">
            <div>Vendas pendentes</div>
            <div class="d-flex justify-content-between">
              <h3 class="m-0 d-none">{{ this.orders.pendings.qty }} <span class="font-size-11">pedidos</span></h3>
              <h3 class="m-0">{{ this.orders.pendings.total }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="card">
          <div class="card-body">
            <div>Prazo médido postagem</div>
            <h3 class="m-0">0 <span class="font-size-11">horas</span></h3>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="card">
          <div class="card-body">
            <div>Faturamento</div>
            <div class="d-flex justify-content-between">
              <h3 class="m-0 d-none">{{ this.orders.approveds.qty }} <span class="font-size-11">pedidos</span></h3>
              <h3 class="m-0">{{ this.orders.approveds.total }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
